/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        
       
       // Carousel
       	$('.owl-mygsmstore').owlCarousel({
			loop:true,
		    margin:10,
		    nav:false,
		    lazyLoad:true,
		    items:1,
		    autoHeight:true,
		    autoplay:true,
			autoplayTimeout:10000,
			autoplayHoverPause:true
		});
       
       
       	$('.menu').click(function() {
	    	var state = $(this).data('state');  
	        state = !state; 
	        if (state) {
	            // do this (1st click, 3rd click, etc)
				$('#menu-primary-navigation').animate({"margin-left": "0"}, 100);
				//$('.fa-bars').animate({"right": "-10"});
	        } else {
	            // do that
			     $('#menu-primary-navigation').animate({"margin-left": "-290px"}, 100);
			     //$('.fa-bars').animate({"right": "20"});
	        }
	        $(this).data('state', state);  
		});
       
       
       
       //Hamburger menu
		var Menu = {
		  
		  el: {
		    ham: $('.menu'),
		    menuTop: $('.menu-top'),
		    menuMiddle: $('.menu-middle'),
		    menuBottom: $('.menu-bottom')
		  },
		  
		  init: function() {
		    Menu.bindUIactions();
		  },
		  
		  bindUIactions: function() {
		    Menu.el.ham
		        .on(
		          'click',
		        function(event) {
		        Menu.activateMenu(event);
		        event.preventDefault();
		      }
		    );
		  },
		  
		  activateMenu: function() {
		    Menu.el.menuTop.toggleClass('menu-top-click');
		    Menu.el.menuMiddle.toggleClass('menu-middle-click');
		    Menu.el.menuBottom.toggleClass('menu-bottom-click'); 
		  }
		};
		
		Menu.init();
       
       
		$('.menu').click (function(){
		  $(this).toggleClass('open');
		});

       
       
		// ACCORDION
		var acc = document.getElementsByClassName("accordion");
		var i;
		
		for (i = 0; i < acc.length; i++) {
		    acc[i].onclick = function(){
		        /* Toggle between adding and removing the "active" class,
		        to highlight the button that controls the panel */
		        this.classList.toggle("active");
				$(this).parent().toggleClass( "active" );
		        /* Toggle between hiding and showing the active panel */
		        var panel = this.nextElementSibling;
		        if (panel.style.display === "block") {
		            panel.style.display = "none";
		        } else {
		            panel.style.display = "block";
		            $(panel).addClass('active');
		        }
		    }
		}
        
		var heightMenu = $( "header.banner").height();
		console.log(heightMenu);
	    $(window).scroll(function() {
	        if ($(this).scrollTop() <= heightMenu) { // if scroll is greater/equal then 100 and hasBeenTrigged is set to false.
					$('nav.main-menu').removeClass('navbar-fixed-top');
	        }else{
		        $('nav.main-menu').addClass('navbar-fixed-top');
	        };
	    });
        
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'contact': {
      init: function() {
        // JavaScript to be fired on the about us page
                /*
			*  new_map
			*
			*  This function will render a Google Map onto the selected jQuery element
			*
			*  @type	function
			*  @date	8/11/2013
			*  @since	4.3.0
			*
			*  @param	$el (jQuery element)
			*  @return	n/a
			*/
			
			function new_map( $el ) {
				
				// var
				var $markers = $el.find('.marker');
				
				
				// vars
				var args = {
					zoom		: 15,
					center		: new google.maps.LatLng(0, 0),
					mapTypeId	: google.maps.MapTypeId.ROADMAP,
					scrollwheel	: false,
					navigationControl	: false,
					streetViewControl: false,
					mapTypeControl	: false,
					scaleControl	: false,
					draggable	: false,
					zoomControl: false,
					styles: [
							    {
							        "featureType": "landscape",
							        "elementType": "labels",
							        "stylers": [
							            {
							                "visibility": "off"
							            }
							        ]
							    },
							    {
							        "featureType": "transit",
							        "elementType": "labels",
							        "stylers": [
							            {
							                "visibility": "off"
							            }
							        ]
							    },
							    {
							        "featureType": "poi",
							        "elementType": "labels",
							        "stylers": [
							            {
							                "visibility": "off"
							            }
							        ]
							    },
							    {
							        "featureType": "water",
							        "elementType": "labels",
							        "stylers": [
							            {
							                "visibility": "off"
							            }
							        ]
							    },
							    {
							        "featureType": "road",
							        "elementType": "labels.icon",
							        "stylers": [
							            {
							                "visibility": "off"
							            }
							        ]
							    },
							    {
							        "stylers": [
							            {
							                "hue": "#00aaff"
							            },
							            {
							                "saturation": -100
							            },
							            {
							                "gamma": 2.15
							            },
							            {
							                "lightness": 12
							            }
							        ]
							    },
							    {
							        "featureType": "road",
							        "elementType": "labels.text.fill",
							        "stylers": [
							            {
							                "visibility": "on"
							            },
							            {
							                "lightness": 24
							            }
							        ]
							    },
							    {
							        "featureType": "road",
							        "elementType": "geometry",
							        "stylers": [
							            {
							                "lightness": 57
							            }
							        ]
							    }
							]

							};
				
				
				// create map	        	
				var map = new google.maps.Map( $el[0], args);
				
				
				// add a markers reference
				map.markers = [];
				
				
				// add markers
				$markers.each(function(){
					
			    	add_marker( $(this), map );
					
				});
				
				map.panBy(-40,80)
				
				// center map
				center_map( map );
				
				
				// return
				return map;
				
			}
			
			/*
			*  add_marker
			*
			*  This function will add a marker to the selected Google Map
			*
			*  @type	function
			*  @date	8/11/2013
			*  @since	4.3.0
			*
			*  @param	$marker (jQuery element)
			*  @param	map (Google Map object)
			*  @return	n/a
			*/
			
			function add_marker( $marker, map ) {
			
				// var
				var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
			
				// create marker
				var marker = new google.maps.Marker({
					position	: latlng,
					map			: map
				});
			
				// add to array
				map.markers.push( marker );
			
				// if marker contains HTML, add it to an infoWindow
				if( $marker.html() )
				{
					// create info window
					var infowindow = new google.maps.InfoWindow({
						content		: $marker.html()
					});
			
					// show info window when marker is clicked
					google.maps.event.addListener(marker, 'click', function() {
			
						infowindow.open( map, marker );
			
					});
				}
			
			}
			
			/*
			*  center_map
			*
			*  This function will center the map, showing all markers attached to this map
			*
			*  @type	function
			*  @date	8/11/2013
			*  @since	4.3.0
			*
			*  @param	map (Google Map object)
			*  @return	n/a
			*/
			
			function center_map( map ) {
			
				// vars
				var bounds = new google.maps.LatLngBounds();
			
				// loop through all markers and create bounds
				$.each( map.markers, function( i, marker ){
			
					var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
			
					bounds.extend( latlng );
			
				});

				// only 1 marker?
				if( map.markers.length == 1 )
				{
					// set center of map
				    map.setCenter( bounds.getCenter());
				    map.setZoom( 15 );

				}
				else
				{
					// fit to bounds
					map.fitBounds( bounds );
				}
			
			}
			
			/*
			*  document ready
			*
			*  This function will render each map when the document is ready (page has loaded)
			*
			*  @type	function
			*  @date	8/11/2013
			*  @since	5.0.0
			*
			*  @param	n/a
			*  @return	n/a
			*/
			// global var
			var map = null;
	
	        $('.acf-map').each(function(){
	
			// create map
			map = new_map( $(this) );
	
			});
		
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
